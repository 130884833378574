<template>
    <b-card>
        <b-form v-on:submit.prevent>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                    label="Roles Name"
                    label-for="rolesName"
                    label-cols-md="2"
                    >
                    <b-form-input
                        id="rolesName"
                        placeholder="Roles Name"
                        v-model="form.name"
                    />
                    </b-form-group>
                </b-col>

                <b-col md="12">
                    <!-- PERMISSION TABLE -->
                    <b-card
                    no-body
                    class="border mt-1"
                    >
                        <b-card-header class="p-1">
                            <b-card-title class="font-medium-2">
                            <feather-icon
                                icon="LockIcon"
                                size="18"
                            />
                            <span class="align-middle ml-50">Permission</span>
                            </b-card-title>
                        </b-card-header>
                        <div class="container">
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="User">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Profile
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="27"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="28"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="29"
                                            >
                                            Update Password
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="30"
                                            >
                                            Image Store
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="31"
                                            >
                                            Push Notification
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Role
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="2"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="role_read"
                                            value="roleread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="4"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="5"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Master">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Position
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="23"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="position_read"
                                            value="positionread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="25"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="26"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Organization
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="8"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="organization_read"
                                            value="organizationread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="10"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="11"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Division
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="13"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="division_read"
                                            value="divisionread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="15"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="16"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Department
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="18"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="department_read"
                                            value="departmentread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="20"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="21"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Status
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="106"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="status_read"
                                            value="statusread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="108"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="109"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            PTKP
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="101"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="ptkp_read"
                                            value="ptkpread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="103"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="104"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Currency
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="125"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="currency_read"
                                            value="currencyread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="127"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="128"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Payment Type
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="152"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="paymenttype_read"
                                            value="paymenttyperead"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="154"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="155"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Shipping Type
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="157"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="shippingtype_read"
                                            value="shippingtyperead"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="159"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="160"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="HR">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Employee
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="employee_create"
                                            value="employeecreate"
                                            checked
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="employee_read"
                                            value="employeeread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="employee_update"
                                            value="employeeupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="36"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="37"
                                            >
                                            Export
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="38"
                                            >
                                            Import
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="39"
                                            >
                                            Image Store
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Attendance
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="attendance_read"
                                            value="attendanceread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="52"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="50"
                                            >
                                            Check In &amp; Out
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="51"
                                            >
                                            Add Manual
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="53"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="55"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Overtime
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="58"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="overtime_read"
                                            value="overtimeread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="59"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="60"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="62"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Permit
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="65"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="permit_read"
                                            value="permitread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="66"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="67"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="69"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Reimbursement
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="72"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="reimbursement_read"
                                            value="reimbursementread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="73"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="74"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="76"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Cash Advance
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="79"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="cashadvance_read"
                                            value="cashadvanceread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="81"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="cashadvance_approval"
                                            value="cashadvanceapproval"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="84"
                                            >
                                            Export
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Payroll
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="payroll_create"
                                            value="payrollcreate"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="payroll_read"
                                            value="payrollread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="payroll_update"
                                            value="payrollupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="payroll_delete"
                                            value="payrolldelete"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Company">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Profile
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="46"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="47"
                                            >
                                            Read
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Product">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Product Unit
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="96"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="productunit_read"
                                            value="productunitread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="98"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="99"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Product Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="91"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="productcategory_read"
                                            value="productcategoryread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="93"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="94"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Products
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="86"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="products_read"
                                            value="productsread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="88"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="89"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Procurement">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Vendor Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="130"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="vendorcategory_read"
                                            value="vendorcategoryread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="132"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="133"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Vendor
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="120"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="vendor_read"
                                            value="vendorread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="122"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="123"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Purchase
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="purchase_create"
                                            value="purchasecreate"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="purchase_read"
                                            value="purchaseread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="purchase_update"
                                            value="purchaseupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="purchase_delete"
                                            value="purchasedelete"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Receiving
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="253"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="procurementreceiving_read"
                                            value="procurementreceivingread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="255"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="256"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Warehouse">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Warehouse Location
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="135"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="warehouselocation_read"
                                            value="warehouselocationread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="137"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="138"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Bin Location
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="146"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="binlocation_read"
                                            value="binlocationread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="147"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="148"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Item
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="140"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="warehouseitem_read"
                                            value="warehouseitemread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="warehouseitem_update"
                                            value="warehouseitemupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Fulfillment
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="fulfillment_create"
                                            value="fulfillmentcreate"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="fulfillment_read"
                                            value="fulfillmentread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="fulfillment_update"
                                            value="fulfillmentupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="fulfillment_delete"
                                            value="fulfillmentdelete"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="218"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Shipping
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="226"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="shipping_read"
                                            value="shippingread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="229"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="shipping_confirm"
                                            value="shippingconfirm"
                                            >
                                            Confirm
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Warehouse Borrow
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="warehouseborrow_read"
                                            value="warehouseborrowread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="232"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Stock Opname
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="236"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="stockopname_read"
                                            value="stockopnameread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="stockopname_update"
                                            value="stockopnameupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="stockopname_confirm"
                                            value="stockopnameconfirm"
                                            >
                                            Confirm
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="241"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Receiving
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="warehousereceiving_read"
                                            value="warehousereceivingread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="259"
                                            >
                                            Update
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Transfer
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="transfer_create"
                                            value="transfercreate"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="transfer_read"
                                            value="transferread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="transfer_update"
                                            value="transferupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="270"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="transfer_confirm"
                                            value="transferconfirm"
                                            >
                                            Confirm
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Replenishment
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="272"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="replenishment_read"
                                            value="replenishmentread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="274"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="275"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Sales">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Cart
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="149"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="150"
                                            >
                                            Read
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Catalog
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="197"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="catalog_read"
                                            value="catalogread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="199"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="200"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Customer Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="167"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="customercategory_read"
                                            value="customercategoryread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="169"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="170"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Customer
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="162"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="customer_read"
                                            value="customerread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="164"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="165"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Quotation
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="quotation_create"
                                            value="quotationcreate"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="quotation_read"
                                            value="quotationread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="quotation_update"
                                            value="quotationupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="quotation_delete"
                                            value="quotationdelete"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Order
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="order_create"
                                            value="ordercreate"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="order_read"
                                            value="orderread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="order_update"
                                            value="orderupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="order_delete"
                                            value="orderdelete"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="191"
                                            >
                                            Approval
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Sales Borrow
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="salesborrow_create"
                                            value="salesborrowcreate"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="salesborrow_read"
                                            value="salesborrowread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="salesborrow_update"
                                            value="salesborrowupdate"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="205"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Task
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="280"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="salestask_read"
                                            value="salestaskread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="282"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="283"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Target
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="285"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="salestarget_read"
                                            value="salestargetread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="287"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="288"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                            <app-collapse accordion class="border shadow p-1 mb-2 rounded">
                                <app-collapse-item title="Asset">
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Category
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="295"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="assetcategory_read"
                                            value="assetcategoryread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="297"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="298"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Location
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="300"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="assetlocation_read"
                                            value="assetlocationread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="302"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="303"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12 my-1 text-primary font-weight-bolder">
                                            Item
                                        </b-col>
                                        <b-col md="12" class="d-flex mb-1">
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="290"
                                            >
                                            Create
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="assetitem_read"
                                            value="assetitemread"
                                            >
                                            Read
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="292"
                                            >
                                            Update
                                            </b-form-checkbox>
                                            <b-form-checkbox
                                            class="mr-5"
                                            v-model="selected"
                                            :value="293"
                                            >
                                            Delete
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </app-collapse-item>
                            </app-collapse>
                        </div>
                    </b-card>
                </b-col>

                <!-- submit and reset -->
                <b-col md="12" class="mt-2" v-if="permissions.includes('role-update')">
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="updateRoles()"
                    >
                    Update
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BTable, BCardHeader, BCardTitle, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
    components: {
        ToastificationContent,
        AppCollapse,
        AppCollapseItem,
        BTable,
        BCardHeader,
        BCardTitle,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                name: ''
            },
            selected: [],
            storage: [],
            // special case
            role_read: '',
            position_read: '',
            organization_read: '',
            division_read: '',
            department_read: '',
            status_read: '',
            ptkp_read: '',
            currency_read: '',
            paymenttype_read: '',
            shippingtype_read: '',
            employee_create: '',
            employee_read: '',
            employee_update: '',
            attendance_read: '',
            overtime_read: '',
            permit_read: '',
            reimbursement_read: '',
            cashadvance_read: '',
            cashadvance_approval: '',
            payroll_create: '',
            payroll_read: '',
            payroll_update: '',
            payroll_delete: '',
            productunit_read: '',
            productcategory_read: '',
            products_read: '',
            vendorcategory_read: '',
            vendor_read: '',
            purchase_create: '',
            purchase_read: '',
            purchase_update: '',
            purchase_delete: '',
            procurementreceiving_read: '',
            warehouselocation_read: '',
            binlocation_read: '',
            warehouseitem_read: '',
            warehouseitem_update: '',
            fulfillment_create: '',
            fulfillment_read: '',
            fulfillment_update: '',
            fulfillment_delete: '',
            shipping_read: '',
            shipping_confirm: '',
            warehouseborrow_read: '',
            stockopname_read: '',
            stockopname_update: '',
            stockopname_confirm: '',
            warehousereceiving_read: '',
            transfer_create: '',
            transfer_read: '',
            transfer_update: '',
            transfer_confirm: '',
            replenishment_read: '',
            catalog_read: '',
            customercategory_read: '',
            customer_read: '',
            quotation_create: '',
            quotation_read: '',
            quotation_update: '',
            quotation_delete: '',
            order_create: '',
            order_read: '',
            order_update: '',
            order_delete: '',
            salesborrow_create: '',
            salesborrow_read: '',
            salesborrow_update: '',
            salestask_read: '',
            salestarget_read: '',
            assetcategory_read: '',
            assetlocation_read: '',
            assetitem_read: '',
            errMessage: '',
            permissions: []
        }
    },
    created() {
        this.getAllPermissions()
        this.getDetails()
    },
    methods: {
        getAllPermissions() {
            this.permissions = sessionStorage.getItem('permissions')
        },
        getDetails() {
            const id = this.$route.params.id
            this.$http
            .get('user/roles/' +id)
            .then((response) => {
                // console.log(response.data.data.permission)
                this.form.name = response.data.data.name
                this.selected = response.data.data.permission

                var $permissions = this.selected
                var i
                for(i=0; i<$permissions.length; i++) {
                    if($permissions[i] === 1 || $permissions[i] === 3 || $permissions[i] === 6) {
                        //real data [1, 3, 6]
                        this.role_read = 'roleread'
                    } else if($permissions[i] === 22 || $permissions[i] === 24) {
                        //real data [22, 24]
                        this.position_read = 'positionread'
                    } else if($permissions[i] === 7 || $permissions[i] === 9) {
                        //real data [7, 9]
                        this.organization_read = 'organizationread'
                    } else if($permissions[i] === 12 || $permissions[i] === 14) {
                        //real data [12, 14]
                        this.division_read = 'divisionread'
                    } else if($permissions[i] === 17 || $permissions[i] === 19) {
                        //real data [17, 19]
                        this.department_read = 'departmentread'
                    } else if($permissions[i] === 105 || $permissions[i] === 107) {
                        //real data [105, 107]
                        this.status_read = 'statusread'
                    } else if($permissions[i] === 100 || $permissions[i] === 102) {
                        //real data [100, 102]
                        this.ptkp_read = 'ptkpread'
                    } else if($permissions[i] === 124 || $permissions[i] === 126) {
                        //real data [124, 126]
                        this.currency_read = 'currencyread'
                    } else if($permissions[i] === 151 || $permissions[i] === 153) {
                        //real data [151, 153]
                        this.paymenttype_read = 'paymenttyperead'
                    } else if($permissions[i] === 156 || $permissions[i] === 158) {
                        //real data [156, 158]
                        this.shippingtype_read = 'shippingtyperead'
                    } else if($permissions[i] === 33 || $permissions[i] === 40 || $permissions[i] === 42 || $permissions[i] === 44) {
                        //real data [33, 40, 42, 44]
                        this.employee_create = 'employeecreate'
                    } else if($permissions[i] === 32 || $permissions[i] === 34) {
                        //real data [32, 34]
                        this.employee_read = 'employeeread'
                    } else if($permissions[i] === 35 || $permissions[i] === 41 || $permissions[i] === 43 || $permissions[i] === 45) {
                        //real data [35, 41, 43, 45]
                        this.employee_update = 'employeeupdate'
                    } else if($permissions[i] === 48 || $permissions[i] === 49 || $permissions[i] === 54) {
                        //real data [48, 49, 54]
                        this.attendance_read = 'attendanceread'
                    } else if($permissions[i] === 56 || $permissions[i] === 57 || $permissions[i] === 61) {
                        //real data [56, 57, 61]
                        this.overtime_read = 'overtimeread'
                    } else if($permissions[i] === 63 || $permissions[i] === 64 || $permissions[i] === 68) {
                        //real data [63, 64, 68]
                        this.permit_read = 'permitread'
                    } else if($permissions[i] === 70 || $permissions[i] === 71 || $permissions[i] === 75) {
                        //real data [70, 71, 75]
                        this.reimbursement_read = 'reimbursementread'
                    } else if($permissions[i] === 77 || $permissions[i] === 78 || $permissions[i] === 83) {
                        //real data [77, 78, 83]
                        this.cashadvance_read = 'cashadvanceread'
                    } else if($permissions[i] === 80 || $permissions[i] === 82) {
                        //real data [80, 82]
                        this.cashadvance_approval = 'cashadvanceapproval'
                    } else if($permissions[i] === 110 || $permissions[i] === 115) {
                        //real data [110, 115]
                        this.payroll_create = 'payrollcreate'
                    } else if($permissions[i] === 111 || $permissions[i] === 114 || $permissions[i] === 116) {
                        //real data [111, 114, 116]
                        this.payroll_read = 'payrollread'
                    } else if($permissions[i] === 112 || $permissions[i] === 117) {
                        //real data [112, 117]
                        this.payroll_update = 'payrollupdate'
                    } else if($permissions[i] === 113 || $permissions[i] === 118) {
                        //real data [113, 118]
                        this.payroll_delete = 'payrolldelete'
                    } else if($permissions[i] === 95 || $permissions[i] === 97) {
                        //real data [95, 97]
                        this.productunit_read = 'productunitread'
                    } else if($permissions[i] === 90 || $permissions[i] === 92) {
                        //real data [90, 92]
                        this.productcategory_read = 'productcategoryread'
                    } else if($permissions[i] === 85 || $permissions[i] === 87) {
                        //real data [85, 87]
                        this.products_read = 'productsread'
                    } else if($permissions[i] === 129 || $permissions[i] === 131) {
                        //real data [129, 131]
                        this.vendorcategory_read = 'vendorcategoryread'
                    } else if($permissions[i] === 119 || $permissions[i] === 121) {
                        //real data [119, 121]
                        this.vendor_read = 'vendorread'
                    } else if($permissions[i] === 243 || $permissions[i] === 248) {
                        //real data [243, 248]
                        this.purchase_create = 'purchasecreate'
                    } else if($permissions[i] === 242 || $permissions[i] === 244 || $permissions[i] === 247 || $permissions[i] === 249) {
                        //real data [242, 244, 247, 249]
                        this.purchase_read = 'purchaseread'
                    } else if($permissions[i] === 245 || $permissions[i] === 250) {
                        //real data [245, 250]
                        this.purchase_update = 'purchaseupdate'
                    } else if($permissions[i] === 246 || $permissions[i] === 251) {
                        //real data [246, 251]
                        this.purchase_delete = 'purchasedelete'
                    } else if($permissions[i] === 252 || $permissions[i] === 254) {
                        //real data [252, 254]
                        this.procurementreceiving_read = 'procurementreceivingread'
                    } else if($permissions[i] === 134 || $permissions[i] === 136) {
                        //real data [134, 136]
                        this.warehouselocation_read = 'warehouselocationread'
                    } else if($permissions[i] === 144 || $permissions[i] === 145) {
                        //real data [144, 145]
                        this.binlocation_read = 'binlocationread'
                    } else if($permissions[i] === 139 || $permissions[i] === 142) {
                        //real data [139, 142]
                        this.warehouseitem_read = 'warehouseitemread'
                    } else if($permissions[i] === 141 || $permissions[i] === 143) {
                        //real data [141, 143]
                        this.warehouseitem_update = 'warehouseitemupdate'
                    } else if($permissions[i] === 214 || $permissions[i] === 220) {
                        //real data [214, 220]
                        this.fulfillment_create = 'fulfillmentcreate'
                    } else if($permissions[i] === 213 || $permissions[i] === 215 || $permissions[i] === 219 || $permissions[i] === 221) {
                        //real data [213, 215, 219, 221]
                        this.fulfillment_read = 'fulfillmentread'
                    } else if($permissions[i] === 216 || $permissions[i] === 222) {
                        //real data [216, 222]
                        this.fulfillment_update = 'fulfillmentupdate'
                    } else if($permissions[i] === 217 || $permissions[i] === 223) {
                        //real data [217, 223]
                        this.fulfillment_delete = 'fulfillmentdelete'
                    } else if($permissions[i] === 224 || $permissions[i] === 225) {
                        //real data [224, 225]
                        this.shipping_read = 'shippingread'
                    } else if($permissions[i] === 227 || $permissions[i] === 228) {
                        //real data [227, 228]
                        this.shipping_confirm = 'shippingconfirm'
                    } else if($permissions[i] === 230 || $permissions[i] === 231 || $permissions[i] === 233 || $permissions[i] === 234) {
                        //real data [230, 231, 233, 234]
                        this.warehouseborrow_read = 'warehouseborrowread'
                    } else if($permissions[i] === 235 || $permissions[i] === 237 || $permissions[i] === 276 || $permissions[i] === 278) {
                        //real data [235, 237, 276, 278]
                        this.stockopname_read = 'stockopnameread'
                    } else if($permissions[i] === 238 || $permissions[i] === 277) {
                        //real data [238, 277]
                        this.stockopname_update = 'stockopnameupdate'
                    } else if($permissions[i] === 239 || $permissions[i] === 240) {
                        //real data [239, 240]
                        this.stockopname_confirm = 'stockopnameconfirm'
                    } else if($permissions[i] === 257 || $permissions[i] === 258) {
                        //real data [257, 258]
                        this.warehousereceiving_read = 'warehousereceivingread'
                    } else if($permissions[i] === 261 || $permissions[i] === 267) {
                        //real data [261, 267]
                        this.transfer_create = 'transfercreate'
                    } else if($permissions[i] === 260 || $permissions[i] === 262 || $permissions[i] === 266 || $permissions[i] === 268) {
                        //real data [260, 262, 266, 268]
                        this.transfer_read = 'transferread'
                    } else if($permissions[i] === 263 || $permissions[i] === 269) {
                        //real data [263, 269]
                        this.transfer_update = 'transferupdate'
                    } else if($permissions[i] === 264 || $permissions[i] === 265) {
                        //real data [264, 265]
                        this.transfer_confirm = 'transferconfirm'
                    } else if($permissions[i] === 271 || $permissions[i] === 273) {
                        //real data [271, 273]
                        this.replenishment_read = 'replenishmentread'
                    } else if($permissions[i] === 196 || $permissions[i] === 198) {
                        //real data [196, 198]
                        this.catalog_read = 'catalogread'
                    } else if($permissions[i] === 166 || $permissions[i] === 168) {
                        //real data [166, 168]
                        this.customercategory_read = 'customercategoryread'
                    } else if($permissions[i] === 161 || $permissions[i] === 163) {
                        //real data [161, 163]
                        this.customer_read = 'customerread'
                    } else if($permissions[i] === 172 || $permissions[i] === 177) {
                        //real data [172, 177]
                        this.quotation_create = 'quotationcreate'
                    } else if($permissions[i] === 171 || $permissions[i] === 173 || $permissions[i] === 176 || $permissions[i] === 178) {
                        //real data [171, 173, 176, 178]
                        this.quotation_read = 'quotationread'
                    } else if($permissions[i] === 174 || $permissions[i] === 179) {
                        //real data [174, 179]
                        this.quotation_update = 'quotationupdate'
                    } else if($permissions[i] === 175 || $permissions[i] === 180) {
                        //real data [175, 180]
                        this.quotation_delete = 'quotationdelete'
                    } else if($permissions[i] === 182 || $permissions[i] === 187 || $permissions[i] === 193) {
                        //real data [182, 187, 193]
                        this.order_create = 'ordercreate'
                    } else if($permissions[i] === 181 || $permissions[i] === 183 || $permissions[i] === 186 || $permissions[i] === 188 || $permissions[i] === 192 || $permissions[i] === 194) {
                        //real data [181, 183, 186, 188, 192, 194]
                        this.order_read = 'orderread'
                    } else if($permissions[i] === 184 || $permissions[i] === 189) {
                        //real data [184, 189]
                        this.order_update = 'orderupdate'
                    } else if($permissions[i] === 185 || $permissions[i] === 190 || $permissions[i] === 195) {
                        //real data [185, 190, 195]
                        this.order_delete = 'orderdelete'
                    } else if($permissions[i] === 202 || $permissions[i] === 210) {
                        //real data [202, 210]
                        this.salesborrow_create = 'salesborrowcreate'
                    } else if($permissions[i] === 201 || $permissions[i] === 203 || $permissions[i] === 209 || $permissions[i] === 211) {
                        //real data [201, 203, 209, 211]
                        this.salesborrow_read = 'salesborrowread'
                    } else if($permissions[i] === 204 || $permissions[i] === 206 || $permissions[i] === 207 || $permissions[i] === 208 || $permissions[i] === 212) {
                        //real data [204, 206, 207, 208, 212]
                        this.salesborrow_update = 'salesborrowupdate'
                    } else if($permissions[i] === 279 || $permissions[i] === 281) {
                        //real data [279, 281]
                        this.salestask_read = 'salestaskread'
                    } else if($permissions[i] === 284 || $permissions[i] === 286) {
                        //real data [284, 286]
                        this.salestarget_read = 'salestargetread'
                    } else if($permissions[i] === 294 || $permissions[i] === 296) {
                        //real data [294, 296]
                        this.assetcategory_read = 'assetcategoryread'
                    } else if($permissions[i] === 299 || $permissions[i] === 301) {
                        //real data [299, 301]
                        this.assetlocation_read = 'assetlocationread'
                    } else if($permissions[i] === 289 || $permissions[i] === 291) {
                        //real data [289, 291]
                        this.assetitem_read = 'assetitemread'
                    } else {
                        this.storage.push($permissions[i])
                    }
                }
                this.selected.splice(0)
                this.selected = this.storage
                // console.log(this.selected)
            }).catch((err) => {
                console.log(err.response)
            });
        },
        updateRoles() {
            this.storage = this.selected
            if(this.role_read === 'roleread') {
                //real data [1, 3, 6]
                this.storage.push(1, 3, 6)
            } 
            if(this.position_read === 'positionread') {
                //real data [22, 24]
                this.storage.push(22, 24)
            } 
            if(this.organization_read === 'organizationread') {
                //real data [7, 9]
                this.storage.push(7, 9)
            } 
            if(this.division_read === 'divisionread') {
                //real data [12, 14]
                this.storage.push(12, 14)
            }
            if(this.department_read === 'departmentread') {
                //real data [17, 19]
                this.storage.push(17, 19)
            }
            if(this.status_read === 'statusread') {
                //real data [105, 107]
                this.storage.push(105, 107)
            }
            if(this.ptkp_read === 'ptkpread') {
                //real data [100, 102]
                this.storage.push(100, 102)
            }
            if(this.currency_read === 'currencyread') {
                //real data [124, 126]
                this.storage.push(124, 126)
            }
            if(this.paymenttype_read === 'paymenttyperead') {
                //real data [151, 153]
                this.storage.push(151, 153)
            }
            if(this.shippingtype_read === 'shippingtyperead') {
                //real data [156, 158]
                this.storage.push(156, 158)
            }
            if(this.employee_create === 'employeecreate') {
                //real data [33, 40, 42, 44]
                this.storage.push(33, 40, 42, 44)
            }
            if(this.employee_read === 'employeeread') {
                //real data [32, 34]
                this.storage.push(32, 34)
            }
            if(this.employee_update === 'employeeupdate') {
                //real data [35, 41, 43, 45]
                this.storage.push(35, 41, 43, 45)
            }
            if(this.attendance_read === 'attendanceread') {
                //real data [48, 49, 54]
                this.storage.push(48, 49, 54)
            }
            if(this.overtime_read === 'overtimeread') {
                //real data [56, 57, 61]
                this.storage.push(56, 57, 61)
            }
            if(this.permit_read === 'permitread') {
                //real data [63, 64, 68]
                this.storage.push(63, 64, 68)
            }
            if(this.reimbursement_read === 'reimbursementread') {
                //real data [70, 71, 75]
                this.storage.push(70, 71, 75)
            }
            if(this.cashadvance_read === 'cashadvanceread') {
                //real data [77, 78, 83]
                this.storage.push(77, 78, 83)
            }
            if(this.cashadvance_approval === 'cashadvanceapproval') {
                //real data [80, 82]
                this.storage.push(80, 82)
            }
            if(this.payroll_create === 'payrollcreate') {
                //real data [110, 115]
                this.storage.push(110, 115)
            }
            if(this.payroll_read === 'payrollread') {
                //real data [111, 114, 116]
                this.storage.push(111, 114, 116)
            }
            if(this.payroll_update === 'payrollupdate') {
                //real data [112, 117]
                this.storage.push(112, 117)
            }
            if(this.payroll_delete === 'payrolldelete') {
                //real data [113, 118]
                this.storage.push(113, 118)
            }
            if(this.productunit_read === 'productunitread') {
                //real data [95, 97]
                this.storage.push(95, 97)
            }
            if(this.productcategory_read === 'productcategoryread') {
                //real data [90, 92]
                this.storage.push(90, 92)
            }
            if(this.products_read === 'productsread') {
                //real data [85, 87]
                this.storage.push(85, 87)
            }
            if(this.vendorcategory_read === 'vendorcategoryread') {
                //real data [129, 131]
                this.storage.push(129, 131)
            }
            if(this.vendor_read === 'vendorread') {
                //real data [119, 121]
                this.storage.push(119, 121)
            }
            if(this.purchase_create === 'purchasecreate') {
                //real data [243, 248]
                this.storage.push(243, 248)
            }
            if(this.purchase_read === 'purchaseread') {
                //real data [242, 244, 247, 249]
                this.storage.push(242, 244, 247, 249)
            }
            if(this.purchase_update === 'purchaseupdate') {
                //real data [245, 250]
                this.storage.push(245, 250)
            }
            if(this.purchase_delete === 'purchasedelete') {
                //real data [246, 251]
                this.storage.push(246, 251)
            }
            if(this.procurementreceiving_read === 'procurementreceivingread') {
                //real data [252, 254]
                this.storage.push(252, 254)
            }
            if(this.warehouselocation_read === 'warehouselocationread') {
                //real data [134, 136]
                this.storage.push(134, 136)
            }
            if(this.binlocation_read === 'binlocationread') {
                //real data [144, 145]
                this.storage.push(144, 145)
            }
            if(this.warehouseitem_read === 'warehouseitemread') {
                //real data [139, 142]
                this.storage.push(139, 142)
            }
            if(this.warehouseitem_update === 'warehouseitemupdate') {
                //real data [141, 143]
                this.storage.push(141, 143)
            }
            if(this.fulfillment_create === 'fulfillmentcreate') {
                //real data [214, 220]
                this.storage.push(214, 220)
            }
            if(this.fulfillment_read === 'fulfillmentread') {
                //real data [213, 215, 219, 221]
                this.storage.push(213, 215, 219, 221)
            }
            if(this.fulfillment_update === 'fulfillmentupdate') {
                //real data [216, 222]
                this.storage.push(216, 222)
            }
            if(this.fulfillment_delete === 'fulfillmentdelete') {
                //real data [217, 223]
                this.storage.push(217, 223)
            }
            if(this.shipping_read === 'shippingread') {
                //real data [224, 225]
                this.storage.push(224, 225)
            }
            if(this.shipping_confirm === 'shippingconfirm') {
                //real data [227, 228]
                this.storage.push(227, 228)
            }
            if(this.warehouseborrow_read === 'warehouseborrowread') {
                //real data [230, 231, 233, 234]
                this.storage.push(230, 231, 233, 234)
            }
            if(this.stockopname_read === 'stockopnameread') {
                //real data [235, 237, 276, 278]
                this.storage.push(235, 237, 276, 278)
            }
            if(this.stockopname_update === 'stockopnameupdate') {
                //real data [238, 277]
                this.storage.push(238, 277)
            }
            if(this.stockopname_confirm === 'stockopnameconfirm') {
                //real data [239, 240]
                this.storage.push(239, 240)
            }
            if(this.warehousereceiving_read === 'warehousereceivingread') {
                //real data [257, 258]
                this.storage.push(257, 258)
            }
            if(this.transfer_create === 'transfercreate') {
                //real data [261, 267]
                this.storage.push(261, 267)
            }
            if(this.transfer_read === 'transferread') {
                //real data [260, 262, 266, 268]
                this.storage.push(260, 262, 266, 268)
            }
            if(this.transfer_update === 'transferupdate') {
                //real data [263, 269]
                this.storage.push(263, 269)
            }
            if(this.transfer_confirm === 'transferconfirm') {
                //real data [264, 265]
                this.storage.push(264, 265)
            }
            if(this.replenishment_read === 'replenishmentread') {
                //real data [271, 273]
                this.storage.push(271, 273)
            }
            if(this.catalog_read === 'catalogread') {
                //real data [196, 198]
                this.storage.push(196, 198)
            }
            if(this.customercategory_read === 'customercategoryread') {
                //real data [166, 168]
                this.storage.push(166, 168)
            }
            if(this.customer_read === 'customerread') {
                //real data [161, 163]
                this.storage.push(161, 163)
            }
            if(this.quotation_create === 'quotationcreate') {
                //real data [172, 177]
                this.storage.push(172, 177)
            }
            if(this.quotation_read === 'quotationread') {
                //real data [171, 173, 176, 178]
                this.storage.push(171, 173, 176, 178)
            }
            if(this.quotation_update === 'quotationupdate') {
                //real data [174, 179]
                this.storage.push(174, 179)
            }
            if(this.quotation_delete === 'quotationdelete') {
                //real data [175, 180]
                this.storage.push(175, 180)
            }
            if(this.order_create === 'ordercreate') {
                //real data [182, 187, 193]
                this.storage.push(182, 187, 193)
            }
            if(this.order_read === 'orderread') {
                //real data [181, 183, 186, 188, 192, 194]
                this.storage.push(181, 183, 186, 188, 192, 194)
            }
            if(this.order_update === 'orderupdate') {
                //real data [184, 189]
                this.storage.push(184, 189)
            }
            if(this.order_delete === 'orderdelete') {
                //real data [185, 190, 195]
                this.storage.push(185, 190, 195)
            }
            if(this.salesborrow_create === 'salesborrowcreate') {
                //real data [202, 210]
                this.storage.push(202, 210)
            }
            if(this.salesborrow_read === 'salesborrowread') {
                //real data [201, 203, 209, 211]
                this.storage.push(201, 203, 209, 211)
            }
            if(this.salesborrow_update === 'salesborrowupdate') {
                //real data [204, 206, 207, 208, 212]
                this.storage.push(204, 206, 207, 208, 212)
            }
            if(this.salestask_read === 'salestaskread') {
                //real data [279, 281]
                this.storage.push(279, 281)
            }
            if(this.salestarget_read === 'salestargetread') {
                //real data [284, 286]
                this.storage.push(284, 286)
            }
            if(this.assetcategory_read === 'assetcategoryread') {
                //real data [294, 296]
                this.storage.push(294, 296)
            }
            if(this.assetlocation_read === 'assetlocationread') {
                //real data [299, 301]
                this.storage.push(299, 301)
            }
            if(this.assetitem_read === 'assetitemread') {
                //real data [289, 291]
                this.storage.push(289, 291)
            }
            // console.log(this.storage)

            const id = this.$route.params.id
            this.$http
            .post("/user/roles/"+id+"/update", {
                "name": this.form.name,
                "permission": this.storage
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Role',
                        variant: 'success',
                    },
                })
                location.href = "/user/roles"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
    },
}
</script>